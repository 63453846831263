import styled from "@emotion/styled"
import { css, SerializedStyles } from "@emotion/react"

import {
  GridSettings,
  calculateMargin,
  calculateOffset,
  calculateWidth,
  maxWidth,
} from "../../variables/layout/Grid"

import {
  breakpoints as defaultBreakpoints,
  BreakpointCSS,
} from "../../variables/layout/Breakpoints"

import { gridSettings as defaultGridSettings } from "../../variables/layout/Grid"

export interface ColumnProps {
  widthMobile?: number
  widthTablet?: number
  widthDesktop?: number
  overflowMobile?: boolean
  overflowTablet?: boolean
  overflowDesktop?: boolean
  offsetMobile?: number
  offsetTablet?: number
  offsetDesktop?: number
  collapseMarginsMobile?: boolean
  collapseMarginsTablet?: boolean
  collapseMarginsDesktop?: boolean
}

// set number of columns width in each size
export const mkColumn =
  (breakpoints: BreakpointCSS) =>
  (gridSettings: GridSettings) =>
  ({
    widthMobile = 1,
    widthTablet = 1,
    widthDesktop = 1,
    overflowMobile = false,
    overflowTablet = false,
    overflowDesktop = false,
    offsetMobile = 0,
    offsetTablet = 0,
    offsetDesktop = 0,
    collapseMarginsMobile = false,
    collapseMarginsTablet = false,
    collapseMarginsDesktop = false,
  }: ColumnProps): SerializedStyles => css`
    box-sizing: border-box;
    ${calculateWidth(gridSettings.mobile, widthMobile, collapseMarginsMobile)}
    ${calculateOffset(gridSettings.mobile, offsetMobile, collapseMarginsMobile)}
  margin-right: ${calculateMargin(
      gridSettings.mobile,
      overflowMobile,
      widthMobile,
      collapseMarginsMobile
    )}px;

    ${breakpoints.tablet`
    ${calculateOffset(gridSettings.tablet, offsetTablet, collapseMarginsTablet)}
    ${calculateWidth(gridSettings.tablet, widthTablet, collapseMarginsTablet)}

    margin-right: ${calculateMargin(
      gridSettings.tablet,
      overflowTablet,
      widthTablet,
      collapseMarginsTablet
    )}px;
  `}

    ${breakpoints.desktop`
    ${calculateWidth(
      gridSettings.desktop,
      widthDesktop,
      collapseMarginsDesktop
    )}

      margin-right: ${calculateMargin(
        gridSettings.desktop,
        overflowDesktop,
        widthDesktop,
        collapseMarginsDesktop
      )}px;
      ${calculateOffset(
        gridSettings.desktop,
        offsetDesktop,
        collapseMarginsDesktop
      )}
  `}
  `

export const column = mkColumn(defaultBreakpoints)(defaultGridSettings)

export const mkGridRow =
  (breakpoints: BreakpointCSS) => (gridSettings: GridSettings) => styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    padding: 0 ${gridSettings.mobile.padding - gridSettings.mobile.margin}px 0
      ${gridSettings.mobile.padding}px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    ${breakpoints.tablet`
    padding: 0 ${gridSettings.tablet.padding - gridSettings.tablet.margin}px 0
    ${gridSettings.tablet.padding}px;
  `}
    ${breakpoints.desktop`
    padding: 0 ${gridSettings.desktop.padding - gridSettings.desktop.margin}px 0
    ${gridSettings.desktop.padding}px;
    max-width: ${maxWidth}px;
  `}
  `

export const GridRow = mkGridRow(defaultBreakpoints)(defaultGridSettings)

// this is the version of RowBox for use inside sub grids
export const mkSubGridRow =
  (breakpoints: BreakpointCSS) => (gridSettings: GridSettings) => styled.div`
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    width: calc(100% + ${gridSettings.mobile.margin}px);
    position: relative;
    ${breakpoints.tablet`
    width: calc(100% + ${gridSettings.tablet.margin}px);
  `}
    ${breakpoints.desktop`
    width: calc(100% + ${gridSettings.desktop.margin}px);
  `}
  `

export const SubGridRow = mkSubGridRow(defaultBreakpoints)(defaultGridSettings)
