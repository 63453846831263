import { breakpointValues } from "./Breakpoints"

export interface GridSetting {
  title: string
  minWidth: number // breakpoint size in px
  padding: number // called margin in the designs, confusingly - this is the space to the left and right of the grid
  margin: number // called gutter in the designs - this is the space between the columns
  maxColumns: number // maximum number of columns per grid size - this is the number of column units we have
}

export interface GridSettings {
  mobile: GridSetting
  tablet: GridSetting
  desktop: GridSetting
}

// the desktop breakpoint is 1280 but we want to use this as the actual maximum
// width
export const maxWidth = 1440

export const gridSettings: GridSettings = {
  desktop: {
    title: "desktop",
    minWidth: breakpointValues.desktop,
    padding: 64,
    margin: 32,
    maxColumns: 12,
  },

  tablet: {
    title: "tablet",
    minWidth: breakpointValues.tablet,
    padding: 32,
    margin: 16,
    maxColumns: 6,
  },

  mobile: {
    title: "mobile",
    minWidth: 0, // designed for 360
    padding: 16,
    margin: 16,
    maxColumns: 4,
  },
}

const min = (a: number, b: number): number => (a < b ? a : b)

export const getColumnWidth = (grid: GridSetting, cols: number): number =>
  ((100 / grid.maxColumns) * min(cols, grid.maxColumns)) / 100

export const calculateWidth = (
  grid: GridSetting,
  cols: number,
  collapseMargins: boolean
): string => {
  const percent = getColumnWidth(grid, cols)
  const sign = collapseMargins ? "+" : "-"
  return `width: calc(${percent * 100}% ${sign} ${grid.margin}px);`
}

export const calculateOffset = (
  grid: GridSetting,
  cols: number,
  collapseMargins: boolean
): string => {
  const percent = getColumnWidth(grid, cols)
  const amount =
    percent == 0
      ? collapseMargins
        ? `-${grid.margin}px`
        : "0px"
      : `calc((${percent * 100}%) - ${collapseMargins ? grid.margin : "0"}px)`
  return `margin-left: ${amount};`
}

export const calculateMargin = (
  grid: GridSetting,
  overflow: boolean,
  cols: number,
  collapseMargins: boolean
): number =>
  cols > 0 && overflow == false && !collapseMargins ? grid.margin : 0
